import { StoreType } from "./store";

export type FirestoreType = {
  instance: any;
  room?: RoomType;
  destinationRoom?: RoomType;
  initFirestore: () => void;
  initRoom: () => void;
  initDestinationRoom: (roomId: string) => Promise<boolean>;
  resetRoom: () => void;
  resetDestinationRoom: () => void;
};

export type RoomType = {
  doc: any;
  offerCandidates: any;
  answerCandidates: any;
};

export const FirestoreDefaults = {
  instance: null,
  room: null,
  destinationRoom: null,
} as FirestoreType;

export const firestoreSelector = (state: StoreType) => state.firestore.instance;
export const roomSelector = (state: StoreType) => state.firestore.room;
export const destinationRoomSelector = (state: StoreType) =>
  state.firestore.destinationRoom;
export const initFirestoreSelector = (state: StoreType) =>
  state.firestore.initFirestore;
export const initRoomSelector = (state: StoreType) => state.firestore.initRoom;
export const initDestinationRoomSelector = (state: StoreType) =>
  state.firestore.initDestinationRoom;

export const resetRoomSelector = (state: StoreType) =>
  state.firestore.resetRoom;
export const resetDestinationRoomSelector = (state: StoreType) =>
  state.firestore.resetDestinationRoom;
