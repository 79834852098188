import * as Peer from "simple-peer";
import { StoreType } from "./store";

export type FileType = {
  state: "TRANSFERING" | "PAUSED";
  id: string;
  name: string;
  type: string;
  size: number;
  percent: number;
  lastModified: number;
  sendedAt: number;
  savedAt: number;
  senderName: string;
  fileObject?: File;
  transfer: any;
};

export type SimplePeerFilesType = {
  send: (peer: Peer, fileID: string, file: File) => Promise<unknown>;
  receive: (peer: Peer, fileID: string) => Promise<unknown>;
};

export type FilesType = {
  fileList: {
    [key: string]: FileType;
  };
  hasFiles: boolean;
  simplePeerFile: any;
  initSimplePeerFile: () => void;
  addFile: (file: FileType) => FileType;
  removeFile: (fileID: string) => FileType;
  pauseFile: (fileID: string) => FileType;
  resumeFile: (fileID: string) => FileType;
  sendFile: (peer: Peer, fileID: string) => FileType;
  receiveFile: (peer: Peer, fileID: string) => FileType;
  downloadFile: (fileID: string) => FileType;
  markFileAsSaved: (fileID: string) => FileType;
};

export const FilesDefaults = {
  fileList: {},
  hasFiles: false,
  simplePeerFile: null,
} as FilesType;

export const handlePercent = (state: FilesType, fileID, percent) => {
  const fileList = state.fileList;
  const file = { ...fileList[fileID] };
  if (!file.id) return null;

  file.percent = percent;
  fileList[fileID] = file;
  return { fileList: fileList };
};

export const handleDoneTransfer = (state: FilesType, fileID, fileObject) => {
  const fileList = state.fileList;
  const file = { ...fileList[fileID] };
  if (!file.id) return null;

  file.percent = 100;
  file.fileObject = fileObject;
  fileList[fileID] = file;
  return { fileList: fileList };
};

export const handleFileTransfer = (state: FilesType, fileID, transfer) => {
  const fileList = state.fileList;
  const file = { ...fileList[fileID] };
  if (!file.id) return null;

  file.transfer = transfer;
  fileList[fileID] = file;
  return { fileList: fileList };
};

export const filesSelector = (state: StoreType) => state.files.fileList;
export const hasFilesSelector = (state: StoreType) => state.files.hasFiles;
export const simplePeerFileSelector = (state: StoreType) =>
  state.files.simplePeerFile;
export const initSimplePeerFileSelector = (state: StoreType) =>
  state.files.initSimplePeerFile;
export const addFileSelector = (state: StoreType) => state.files.addFile;
export const removeFileSelector = (state: StoreType) => state.files.removeFile;
export const pauseFileSelector = (state: StoreType) => state.files.pauseFile;
export const resumeFileSelector = (state: StoreType) => state.files.resumeFile;
export const sendFileSelector = (state: StoreType) => state.files.sendFile;
export const receiveFileSelector = (state: StoreType) =>
  state.files.receiveFile;
export const downloadFileSelector = (state: StoreType) =>
  state.files.downloadFile;
export const markFileAsSavedSelector = (state: StoreType) =>
  state.files.markFileAsSaved;
