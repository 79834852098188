export const peerConfig = {
  iceServers: [
    {
      urls: "stun:stun.relay.metered.ca:80",
    },
    {
      urls: "turn:a.relay.metered.ca:80",
      username: "ac8b270d08d94f6250f1098b",
      credential: "RsgtEEhJW2JA3AX7",
    },
    {
      urls: "turn:a.relay.metered.ca:80?transport=tcp",
      username: "ac8b270d08d94f6250f1098b",
      credential: "RsgtEEhJW2JA3AX7",
    },
    {
      urls: "turn:a.relay.metered.ca:443",
      username: "ac8b270d08d94f6250f1098b",
      credential: "RsgtEEhJW2JA3AX7",
    },
    {
      urls: "turn:a.relay.metered.ca:443?transport=tcp",
      username: "ac8b270d08d94f6250f1098b",
      credential: "RsgtEEhJW2JA3AX7",
    },
  ],
};
