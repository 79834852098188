import { StoreType } from "./store";

export type MessageType = {
  id: string;
  name: string;
  value: string;
  createdAt: number;
  type: string;
};

export type ChatType = {
  unreadedMessages: number;
  messages: MessageType[];
  increaseUnreadedMessages: () => void;
  resetUnreadedMessages: () => void;
  addMessage: (args: MessageType) => void;
};

export const ChatDefaults = {
  unreadedMessages: 0,
  messages: [],
} as ChatType;

export const unreadedMessagesSelector = (state: StoreType) =>
  state.chat.unreadedMessages;
export const messagesSelector = (state: StoreType) => state.chat.messages;
export const increaseUnreadedMessagesSelector = (state: StoreType) =>
  state.chat.increaseUnreadedMessages;
export const resetUnreadedMessagesSelector = (state: StoreType) =>
  state.chat.resetUnreadedMessages;
export const addMessageSelector = (state: StoreType) => state.chat.addMessage;
