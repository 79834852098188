import { StoreType } from "./store";

export type PeerType = {
  me: {
    name: string;
    id: string;
  };
  other: {
    name: string;
    id: string;
  };
  isInitiator: boolean;
  connectionStatus: "DISCONNECTED" | "CONNECTED" | "CONNECTING";
  instance: any;
  initPeer: (value?: boolean) => void;
  setConnectionStatus: (
    value: "DISCONNECTED" | "CONNECTED" | "CONNECTING"
  ) => void;
  setMe: (value: { name: string; id: string }) => void;
  setOther: (value: { name: string; id: string }) => void;
};

export const PeerDefaults = {
  me: {
    name: "",
    id: "",
  },
  other: {
    name: "",
    id: "",
  },
  isInitiator: false,
  connectionStatus: "DISCONNECTED",
  instance: null,
} as PeerType;

export const peerSelector = (state: StoreType) => state.peer;
export const meSelector = (state: StoreType) => state.peer.me;
export const otherSelector = (state: StoreType) => state.peer.other;

export const setMeSelector = (state: StoreType) => state.peer.setMe;
export const setOtherSelector = (state: StoreType) => state.peer.setOther;

export const connectionStatusSelector = (state: StoreType) =>
  state.peer.connectionStatus;
export const peerInstanceSelector = (state: StoreType) => state.peer.instance;
export const setConnectionStatusSelector = (state: StoreType) =>
  state.peer.setConnectionStatus;
export const initPeerSelector = (state: StoreType) => state.peer.initPeer;
