import FileSaver from "file-saver";
import firebase from "firebase/app";
import "firebase/firestore";
import throttle from "lodash.throttle";
import Peer from "simple-peer";
import SimplePeerFiles from "simple-peer-files";
import create from "zustand";
import { firebaseConfig } from "../config/firebase";
import { peerConfig } from "../config/simplePeer";
import { ChatDefaults, ChatType } from "./chat";
import {
  FilesDefaults,
  FilesType,
  handleDoneTransfer,
  handleFileTransfer,
  handlePercent,
} from "./files";
import { FirestoreDefaults, FirestoreType } from "./firestore";
import { PeerDefaults, PeerType } from "./peer";

export type StoreType = {
  konami: boolean;
  toggleKonami: () => void;
  peer: PeerType;
  firestore: FirestoreType;
  chat: ChatType;
  files: FilesType;
};

export const konamiSelector = (state: StoreType) => state.konami;
export const toggleKonamiSelector = (state: StoreType) => state.toggleKonami;

export const useStore = create<StoreType>((set, get) => ({
  konami: false,
  toggleKonami: () => set((state) => ({ konami: !state.konami })),
  //----------------------------------//
  // ---------- PEER STATE ---------- //
  //----------------------------------//
  peer: {
    ...PeerDefaults,
    initPeer: (initiator) => {
      const state = get();

      if (state.peer.instance) state.peer.instance.destroy();

      set((state) => ({
        peer: {
          ...state.peer,
          isInitiator: initiator ?? state.peer.isInitiator,
          instance: new Peer({
            initiator: initiator ?? state.peer.isInitiator,
            config: peerConfig,
          }),
        },
      }));
    },
    setMe: (me) => {
      set((state) => ({
        peer: {
          ...state.peer,
          me: { ...state.peer.me, ...me },
        },
      }));
    },
    setOther: (other) => {
      set((state) => ({
        peer: {
          ...state.peer,
          other: { ...state.peer.other, ...other },
        },
      }));
    },
    setConnectionStatus: (status) => {
      set((state) => ({
        peer: {
          ...state.peer,
          connectionStatus: status,
        },
      }));
    },
  },
  //---------------------------------------//
  // ---------- FIRESTORE STATE ---------- //
  //---------------------------------------//
  firestore: {
    ...FirestoreDefaults,
    initFirestore: () => {
      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
      }

      set((state) => ({
        firestore: { ...state.firestore, instance: firebase.firestore() },
      }));
    },
    initRoom: () => {
      const state = get();
      const myID = state.peer.me.id;
      const firestore = state.firestore.instance;
      if (!myID || !firestore) return;

      const doc = firestore.collection("rooms").doc(myID);
      doc.set({
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      });
      const offerCandidates = doc.collection("offerCandidates");
      const answerCandidates = doc.collection("answerCandidates");

      set((state) => ({
        firestore: {
          ...state.firestore,
          room: { doc, offerCandidates, answerCandidates },
        },
      }));
    },
    initDestinationRoom: (roomId) => {
      return new Promise(function (resolve) {
        const state = get();
        const firestore = state.firestore.instance;
        if (!firestore) resolve(false);

        const doc = firestore.collection("rooms").doc(roomId);

        doc
          .get()
          .then((d) => {
            if (d.exists) {
              const offerCandidates = doc.collection("offerCandidates");
              const answerCandidates = doc.collection("answerCandidates");

              set((state) => ({
                firestore: {
                  ...state.firestore,
                  destinationRoom: { doc, offerCandidates, answerCandidates },
                },
              }));
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            resolve(false);
          });
      });
    },
    resetRoom: () =>
      set((state) => ({
        firestore: {
          ...state.firestore,
          room: null,
        },
      })),
    resetDestinationRoom: () =>
      set((state) => ({
        firestore: {
          ...state.firestore,
          destinationRoom: null,
        },
      })),
  },
  //----------------------------------//
  // ---------- CHAT STATE ---------- //
  //----------------------------------//
  chat: {
    ...ChatDefaults,
    increaseUnreadedMessages: () =>
      set((state) => ({
        chat: {
          ...state.chat,
          unreadedMessages: state.chat.unreadedMessages + 1,
        },
      })),
    resetUnreadedMessages: () =>
      set((state) => ({
        chat: {
          ...state.chat,
          unreadedMessages: 0,
        },
      })),
    addMessage: (message) =>
      set((state) => ({
        chat: {
          ...state.chat,
          messages: [...state.chat.messages, message],
        },
      })),
  },
  files: {
    ...FilesDefaults,
    initSimplePeerFile: () => {
      const spf = new SimplePeerFiles();
      set((state) => ({
        files: { ...state.files, simplePeerFile: spf },
      }));
    },
    addFile: (file) => {
      set((state) => ({
        files: {
          ...state.files,
          hasFiles: true,
          fileList: { ...state.files.fileList, [file.id]: file },
        },
      }));
      return file;
    },
    removeFile: (fileID) => {
      const { ...fileList } = get().files.fileList;
      const file = fileList[fileID];
      delete fileList[fileID];
      const hasFiles = Object.entries(fileList).length > 0;
      set((state) => ({
        files: { ...state.files, hasFiles, fileList: fileList },
      }));

      return file;
    },
    pauseFile: (fileID) => {
      const fileList = get().files.fileList;
      const file = { ...fileList[fileID] };
      if (!file.id) return null;

      file.state = "PAUSED";
      fileList[fileID] = file;
      set((state) => ({ files: { ...state.files, fileList: fileList } }));

      return file;
    },
    resumeFile: (fileID) => {
      const fileList = get().files.fileList;
      const file = { ...fileList[fileID] };
      if (!file.id) return null;

      file.state = "TRANSFERING";
      fileList[fileID] = file;
      set((state) => ({ files: { ...state.files, fileList: fileList } }));

      return file;
    },
    sendFile: (peer, fileID) => {
      const file = get().files.fileList[fileID];
      if (!file) return;

      const simplePeerFile = get().files.simplePeerFile;
      simplePeerFile.send(peer, fileID, file.fileObject).then((transfer) => {
        transfer.on(
          "progress",
          throttle((percent) => {
            set((state) => ({
              files: {
                ...state.files,
                ...handlePercent(state.files, fileID, percent),
              },
            }));
          }, 100)
        );

        set((state) => ({
          files: {
            ...state.files,
            ...handleFileTransfer(state.files, fileID, transfer),
          },
        }));

        transfer.start();
      });
      return file;
    },
    receiveFile: (peer, fileID) => {
      const state = get();
      const simplePeerFile = state.files.simplePeerFile;

      simplePeerFile.receive(peer, fileID).then((transfer) => {
        transfer.on(
          "progress",
          throttle((percent) => {
            set((state) => ({
              files: {
                ...state.files,
                ...handlePercent(state.files, fileID, percent),
              },
            }));
          }, 100)
        );

        transfer.on("done", (fileObject) => {
          set((state) => ({
            files: {
              ...state.files,
              ...handleDoneTransfer(state.files, fileID, fileObject),
            },
          }));
        });

        set((state) => ({
          files: {
            ...state.files,
            ...handleFileTransfer(state.files, fileID, transfer),
          },
        }));
      });

      peer.send(JSON.stringify({ type: "SEND-FILE", id: fileID }));

      return state.files.fileList[fileID];
    },
    downloadFile: (fileID) => {
      const file = get().files.fileList[fileID];

      if (!file && !file.fileObject) return;

      const { fileObject } = file;
      FileSaver.saveAs(fileObject, fileObject.name);
      return file;
    },
    markFileAsSaved: (fileID) => {
      let file;

      set((state) => {
        const fileList = state.files.fileList;
        file = { ...fileList[fileID] };
        file.savedAt = new Date().getTime();
        fileList[fileID] = file;
        return { files: { ...state.files, fileList: fileList } };
      });

      return file;
    },
  },
}));
